import { LOCALE_ID, NgModule }     from '@angular/core';
import { registerLocaleData }      from '@angular/common';
import { HttpClientModule }        from '@angular/common/http';
import { BrowserModule }           from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localePt from '@angular/common/locales/pt';
import 'moment/locale/pt-br';

import { ToastrModule }                       from 'ngx-toastr';
import { CookieService }                      from 'ngx-cookie-service';
import { NgProgressConfig, NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule }               from 'ngx-progressbar/http';
import { httpInterceptorProviders }           from '@interceptors/http-interceptor-providers';

import { LayoutModule }     from '@layout/layout.module';
import { IconsModule }      from '@shared/icons/icons.module';
import { MaterialModule }   from '@shared/material/material.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent }      from './app.component';
import { RedirectComponent } from './redirect/redirect.component';
 
registerLocaleData(localePt, 'pt-BR');

const progressConfig: NgProgressConfig = {
  color:   '#5ed4c5',
  thick:   true,
  spinner: false
};

@NgModule(
  {
    declarations: [
      AppComponent,
      RedirectComponent,
    ],
    imports:      [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      HttpClientModule,
      NgProgressModule.withConfig(progressConfig),
      NgProgressHttpModule,
      ToastrModule.forRoot(),
      MaterialModule,
      IconsModule,
      LayoutModule,
    ],
    providers:    [
      httpInterceptorProviders,
      CookieService,
      { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    bootstrap:    [
      AppComponent
    ]
  }
)
export class AppModule {}
