import { Injectable } from '@angular/core';

import { filter } from 'rxjs/operators';

import { FullCustomDimensionsSet }    from '@models/gtm/full-custom-dimensions-set';
import { PartialCustomDimensionsSet } from '@models/gtm/partial-custom-dimensions-set';
import { AuthUser }                   from '@models/user/auth-user';
import { DataLayerCustomDimensions }  from '@models/gtm/data-layer-custom-dimensions.enum';

import { UserService } from '@services/user.service';


declare global { interface Window { dataLayer: any; }}

@Injectable({ providedIn: 'root' })
export class GtmDataLayerService {

  private readonly currentSet: FullCustomDimensionsSet;
  private defaultSet: FullCustomDimensionsSet;

  constructor(
    private userService: UserService
  ) {
    this.currentSet = GtmDataLayerService.generateEmptySet();
    this.defaultSet = GtmDataLayerService.generateEmptySet();

    this.addUserData();
  }

  public set dimensions(dimensions: PartialCustomDimensionsSet) {
    const keys = Object.keys(DataLayerCustomDimensions)
                       .map(key => DataLayerCustomDimensions[key]);

    keys.forEach(key => this.currentSet[key] = dimensions[key] || this.defaultSet[key]);
  }

  private static generateEmptySet(): FullCustomDimensionsSet {
    return {
      [DataLayerCustomDimensions.USER_ID]:   undefined,
      [DataLayerCustomDimensions.USER_TYPE]: undefined
    };
  }

  private addUserData() {
    this.userService.getActiveUser().pipe(
      filter((user: AuthUser) => !!user && typeof user.uuid === 'string')
    ).subscribe((user: AuthUser) => {
      this.defaultSet = {
        ...this.defaultSet,
        [DataLayerCustomDimensions.USER_ID]:   user.uuid,
        [DataLayerCustomDimensions.USER_TYPE]: user.perfil
      };
    });
  }

  public trigger() {
    if ((window as Window) && window.dataLayer) window.dataLayer.push(this.currentSet);
  }
}
