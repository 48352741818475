import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { AlertBoxModule }      from './alert-box/alert-box.module';
import { AnchorModule }        from './anchor/anchor.module';
import { IconsModule }         from './icons/icons.module';
import { MaterialModule }      from './material/material.module';
import { PipesModule }         from './pipes/pipes.module';
import { ImageModule }         from './image/image.module';
import { ModalsModule }        from '@modals/modals.module';
import { TimerComponent }      from './timer/timer.component';
import { EditorModule }        from './editor/editor.module';
import { QuestionnaireModule } from './questionnaire/questionnaire.module';


@NgModule({
  declarations: [
    TimerComponent
  ],
  imports:      [
    CommonModule,
    AlertBoxModule,
    AnchorModule,
    IconsModule,
    VirtualScrollerModule,
    MaterialModule,
    PipesModule,
    ModalsModule,
    ImageModule,
    EditorModule,
    QuestionnaireModule
  ],
  exports:      [
    AlertBoxModule,
    AnchorModule,
    IconsModule,
    VirtualScrollerModule,
    MaterialModule,
    PipesModule,
    ModalsModule,
    ImageModule,
    TimerComponent,
    QuestionnaireModule
  ]
})
export class SharedModule {}
