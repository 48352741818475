import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { RouterModule }                     from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule }                    from '@angular/cdk/overlay';

import { SharedModule } from '../shared/shared.module';

import { FooterComponent }               from './footer/footer.component';
import { HamburgerMenuComponent }        from './hamburger-menu/hamburger-menu.component';
import { LayoutBareComponent }           from './layout-bare/layout-bare.component';
import { LayoutDashboardComponent }      from './layout-dashboard/layout-dashboard.component';
import { LayoutInstitutionalComponent }  from './layout-institutional/layout-institutional.component';
import { ToolbarInstitutionalComponent } from './toolbar-institutional/toolbar-institutional.component';
import { ToolbarComponent }              from './toolbar/toolbar.component';
import { AuthOverlayComponent }          from './auth-overlay/auth-overlay.component';
import { ContractOverlayComponent }      from './contract-overlay/contract-overlay.component';


@NgModule({
  declarations: [
    ToolbarComponent,
    HamburgerMenuComponent,
    FooterComponent,
    LayoutBareComponent,
    LayoutDashboardComponent,
    LayoutInstitutionalComponent,
    ToolbarInstitutionalComponent,
    AuthOverlayComponent,
    ContractOverlayComponent
  ],
  imports:      [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    SharedModule
  ],
  exports:      [
    ToolbarComponent,
    HamburgerMenuComponent,
    FooterComponent,
    LayoutBareComponent,
    LayoutDashboardComponent,
    LayoutInstitutionalComponent,
    ToolbarInstitutionalComponent,
    AuthOverlayComponent,
    ContractOverlayComponent
  ]
})
export class LayoutModule {}
