import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { AlertBoxModule }         from '@shared/alert-box/alert-box.module';
import { FontAwesomeModule }      from '@fortawesome/angular-fontawesome';
import { MatDividerModule }       from '@angular/material/divider';
import { MatRadioModule }         from '@angular/material/radio';
import { MatRippleModule }        from '@angular/material/core';
import { PipesModule }            from '@shared/pipes/pipes.module';
import { ReactiveFormsModule }    from '@angular/forms';


@NgModule({
  declarations: [
    QuestionnaireComponent
  ],
  imports: [
    CommonModule,
    AlertBoxModule,
    FontAwesomeModule,
    MatDividerModule,
    MatRadioModule,
    MatRippleModule,
    PipesModule,
    ReactiveFormsModule
  ],
  exports: [
    QuestionnaireComponent
  ]
})
export class QuestionnaireModule { }
