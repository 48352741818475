import { CommonModule } from '@angular/common';
import { NgModule }     from '@angular/core';

import { IconsModule } from '../icons/icons.module';
import { PipesModule } from '../pipes/pipes.module';

import { AlertBoxComponent } from './alert-box/alert-box.component';


@NgModule({
  declarations: [ AlertBoxComponent ],
  imports:      [ CommonModule, IconsModule, PipesModule ],
  exports:      [ AlertBoxComponent ]
})
export class AlertBoxModule {}
