import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatProgressBarModule } from '@angular/material/progress-bar';

import { EditorImageComponent } from '@shared/editor/editor-image/editor-image.component';
import { EditorFlagFormComponent } from './editor-flag-form/editor-flag-form.component';
import { EditorMarkComponent } from '@shared/editor/editor-mark/editor-mark.component';
import { EditorMarkInfoComponent } from './editor-mark-info/editor-mark-info.component';
import { EditorTextComponent } from './editor-text/editor-text.component';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '@shared/pipes/pipes.module';


@NgModule({
  declarations: [
    EditorImageComponent,
    EditorFlagFormComponent,
    EditorMarkComponent,
    EditorMarkInfoComponent,
    EditorTextComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatInputModule,
    PipesModule,
    FormsModule
  ],
  exports: [
    EditorImageComponent,
    EditorFlagFormComponent,
    EditorMarkComponent,
    EditorMarkInfoComponent,
    EditorTextComponent
  ]
})
export class EditorModule { }
