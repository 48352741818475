import { Injectable } from '@angular/core';
import { Router }     from '@angular/router';

import { filter, take } from 'rxjs/operators';

import { AuthUser }    from '@models/user/auth-user';
import { UserService } from '@services/user.service';


export type CheckRouteFn = (user: AuthUser, router: Router) => void;

@Injectable({ providedIn: 'root' })
export class HubsService {

  constructor(
    private router:      Router,
    private userService: UserService
  ) { }

  public checkUserType(checkRouteFn: CheckRouteFn) {

    if (this.userService.isLoggedIn() || this.userService.hasValidPidAuthParams()) {
      this.userService.initUser().pipe(
        filter((user: AuthUser) => !!user),
        take(1)
      ).subscribe((user: AuthUser) => checkRouteFn(user, this.router));

      return;
    }

    this.router.navigate([ '/home' ]).then();
  }

}
