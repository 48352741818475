import { Injectable } from '@angular/core';
import { Router }     from '@angular/router';

import { PartialCustomDimensionsSet } from '../../shared/models/gtm/partial-custom-dimensions-set';
import { GtmDataLayerService }        from './gtm-data-layer.service';


@Injectable({ providedIn: 'root' })
export class AnalyticsService {

  constructor(
    private router: Router,
    private gtmDataLayerService: GtmDataLayerService
  ) { }

  public pageView(
    dimensions: PartialCustomDimensionsSet = {},
    overridePath?: string
  ) {
    if (!window) return;

    this.gtmDataLayerService.dimensions = dimensions;
    this.gtmDataLayerService.trigger();

    const virtualPageView = {
      event:            'virtualPageview',
      virtualPageUrl:   overridePath || this.router.url,
      virtualPageTitle: overridePath || this.router.url
    };

    window.dataLayer.push(virtualPageView);
  }
}
