import { Injectable } from '@angular/core';
import { Router }     from '@angular/router';

import { BehaviorSubject, Observable, of, tap, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map }    from 'rxjs/operators';

import { AuthUser }      from '@models/user/auth-user';
import { PidAuthParams } from '@models/user/pid-auth-params';
import { UserType }      from '@models/user/user-type';

import { ApiUserService }    from '@services/api-user.service';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class UserService {

  private readonly _activeUser$: Observable<AuthUser>;
  private readonly _token$:      Observable<string>;

  private _activeUserSource$: BehaviorSubject<AuthUser>;
  private _tokenSource$:      BehaviorSubject<string>;

  private _redirectRoute:    (string | number)[];
  private _token:            string;
  private _contractAccepted: boolean;
  private _pidAuthParams:    PidAuthParams;
  private _partnerId:        number;
  private _hasUser:          boolean;
  private _retrievingUser:   boolean;

  public static getQueryParam(paramName: string): string {
    const regex: RegExp           = new RegExp(`[?&]${ paramName }(=([^&#]*)|&|#|$)`),
          result: RegExpExecArray = regex.exec(window.location.search);

    return result && result[2];
  }

  constructor(
    private router:         Router,
    private apiUserService: ApiUserService,
  ) {
    this._activeUserSource$ = new BehaviorSubject<AuthUser>(null);
    this._activeUser$       = this._activeUserSource$.asObservable();

    this._tokenSource$ = new BehaviorSubject<string>(null);
    this._token$       = this._tokenSource$.asObservable();

    this._retrievingUser = false;

    this.resetRedirectRoute();
  }

  /* INIT USER */

  public initUser(): Observable<AuthUser> {
    if (this.hasUser || this._retrievingUser) return this.getActiveUser();

    this._retrievingUser = true;

    const callback = (user: AuthUser) => {
      this._retrievingUser = false;
      this.updateActiveUser(user);
    };

    const errorCallback = (error: HttpErrorResponse) => {
      this._retrievingUser = false;
      this.logout();
      return throwError(() => error);
    };

    if (this.hasValidPidAuthParams()) {
      return this.apiUserService.pidAuthenticate(this.pidAuthParams).pipe(
        catchError(errorCallback), tap(callback)
      );
    }

    if (this.isLoggedIn()) {
      return this.apiUserService.getUser(this.getToken()).pipe(
        catchError(errorCallback), tap(callback)
      );
    }

    return of(null);
  }

  /* JWT TOKEN */

  private setToken(token: string) {
    if (this._token === token) return;

    this._token = token;
    this.updateTokenStorage(token);
    this.updateTokenObservable(token);
  }

  public getToken(): string {
    this.checkStorageToken();
    return this._token;
  }

  private checkStorageToken() {
    const localStorageJwt: string = localStorage.getItem('red1000Jwt');
    if (localStorageJwt) this.setToken(localStorageJwt);
  }

  private updateTokenStorage(token: string) {
    if (!token) {
      localStorage.removeItem('red1000Jwt');
      localStorage.removeItem('partnerId');
      return;
    }

    localStorage.setItem('red1000Jwt', token);
  }

  public updateTokenObservable(token: string): void {
    this._tokenSource$.next(token);
  }

  public getTokenObservable(): Observable<string> {
    return this._token$.pipe(distinctUntilChanged());
  }


  /* ACTIVE USER STATUS */

  public isLoggedIn(): boolean { return !!this.getToken(); }

  public updateActiveUser(user: AuthUser): void {
    this.setToken(user?.jwtToken || null);

    this.partnerId        = user?.parceiroId || null;
    this.contractAccepted = !user?.aceiteContrato;
    this.hasUser          = !!user;

    this._activeUserSource$.next(user);
  }

  public getActiveUser(): Observable<AuthUser> { return this._activeUser$; }

  public getUserType(): Observable<UserType> {
    return this._activeUser$.pipe(
      filter((user: AuthUser) => !!user?.perfil),
      map((user: AuthUser) => user.perfil)
    );
  }

  public isUserAdmin(): Observable<boolean> {
    return this.getUserType().pipe(map((userType: UserType) => {
      return this.isUserTypeAdmin(userType);
    }));
  }

  public isUserSuperAdmin(): Observable<boolean> {
    return this.getUserType().pipe(map(this.isUserTypeSuperAdmin));
  }

  public isUserTypeAdmin(userType: UserType): boolean {
    return userType === 'ADMIN' || userType === 'SUPER_ADMIN';
  }

  public isUserTypeSuperAdmin(userType: UserType): boolean {
    return userType === 'SUPER_ADMIN';
  }

  public get hasUser(): boolean      { return this._hasUser; }
  public set hasUser(value: boolean) { this._hasUser = value; }

  public get contractAccepted(): boolean      { return this._contractAccepted; }
  public set contractAccepted(value: boolean) { this._contractAccepted = value; }


  /* PARTNER AUTHENTICATION */

  public get pidAuthParams(): PidAuthParams {
    if (this._pidAuthParams) return this._pidAuthParams;

    const pid: string               = UserService.getQueryParam('pid'),
          tid: string               = UserService.getQueryParam('tid'),
          usuarioParceiroId: string = UserService.getQueryParam('usuarioParceiroId') || null,
          token: string             = UserService.getQueryParam('token');

    this.pidAuthParams = { pid, tid, usuarioParceiroId, token };

    return this._pidAuthParams;
  }

  public set pidAuthParams(value: PidAuthParams) {
    this._pidAuthParams = value;
  }

  public hasValidPidAuthParams(): boolean {
    const params: PidAuthParams = this.pidAuthParams;
    return !!(params && params.pid && params.tid && params.token);
  }

  public set partnerId(partnerId: number) {
    this._partnerId = partnerId;
    localStorage.setItem('partnerId', `${ partnerId }`);
  }

  public get partnerId(): number {
    if (this._partnerId) return this._partnerId;

    const storedPartnerId: string = localStorage.getItem('partnerId');

    return storedPartnerId ? +storedPartnerId : null;
  }

  /* LOGOUT & REDIRECT */

  public get redirectRoute(): (string | number)[]      { return this._redirectRoute; }
  public set redirectRoute(route: (string | number)[]) { this._redirectRoute = route; }

  public resetRedirectRoute(): void { this.redirectRoute = [ '/' ]; }

  public logout(): void {
    this.resetRedirectRoute();
    this.updateActiveUser(null);

    this.router.navigate([ '/home' ]).then();
  }
}
