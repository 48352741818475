import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RedirectComponent } from './redirect/redirect.component';


const loadInstitutional    = () => import('@modules/institutional/institutional.module').then(m => m.InstitutionalModule),
      loadUser             = () => import('@modules/user/user.module').then(m => m.UserModule),
      loadRegistry         = () => import('@modules/registry/registry.module').then(m => m.RegistryModule),
      loadProofreading     = () => import('@modules/proofreading/proofreading.module').then(m => m.ProofreadingModule),
      loadEssay            = () => import('@modules/essay/essay.module').then(m => m.EssayModule),
      loadThemes           = () => import('@modules/themes/themes.module').then(m => m.ThemesModule),
      loadTask             = () => import('@modules/task/task.module').then(m => m.TaskModule),
      loadReports          = () => import('@modules/reports/reports.module').then(m => m.ReportsModule),
      loadDashboard        = () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      loadMarathon         = () => import('@modules/marathon/marathon.module').then(m => m.MarathonModule),
      loadManuals          = () => import('@modules/manuals/manuals.module').then(m => m.ManualsModule),
      loadSchedule         = () => import('@modules/schedule/schedule.module').then(m => m.ScheduleModule),
      loadService          = () => import('@modules/service/service.module').then(m => m.ServiceModule),
      loadQuestionnaire    = () => import('@modules/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
      loadRecruitment      = () => import('@modules/recruitment/recruitment.module').then(m => m.RecruitmentModule),
      loadAdaptiveLearning = () => import('@modules/adaptive-learning/adaptive-learning.module').then(m => m.AdaptiveLearningModule);


const routes: Routes = [
  { path: '', pathMatch: 'full', component: RedirectComponent },

  // modules
  { path: 'home',              loadChildren: loadInstitutional },
  { path: 'usuario',           loadChildren: loadUser },
  { path: 'cadastro',          loadChildren: loadRegistry },
  { path: 'correcao',          loadChildren: loadProofreading },
  { path: 'redacao',           loadChildren: loadEssay },
  { path: 'tema',              loadChildren: loadThemes },
  { path: 'tarefa',            loadChildren: loadTask },
  { path: 'relatorio',         loadChildren: loadReports },
  { path: 'dash',              loadChildren: loadDashboard },
  { path: 'maratona',          loadChildren: loadMarathon },
  { path: 'manuais',           loadChildren: loadManuals },
  { path: 'programacao',       loadChildren: loadSchedule },
  { path: 'atendimento',       loadChildren: loadService },
  { path: 'avaliacao',         loadChildren: loadQuestionnaire },
  { path: 'processo-seletivo', loadChildren: loadRecruitment },
  { path: 'ensino-adaptativo', loadChildren: loadAdaptiveLearning },

  // redirects
  { path: 'sobre',                         pathMatch: 'full', redirectTo: 'home/sobre' },
  { path: 'para-escolas',                  pathMatch: 'full', redirectTo: 'home/para-escolas' },
  { path: 'esqueci-senha/redefinir/senha', pathMatch: 'full', redirectTo: 'home' },
  { path: '**',                            redirectTo: '/home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      initialNavigation:         'enabled',
      onSameUrlNavigation:       'reload',
      anchorScrolling:           'enabled',
      scrollPositionRestoration: 'enabled',
      enableTracing:             false,
      relativeLinkResolution:    'legacy'
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
