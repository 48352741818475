import { A11yModule } from '@angular/cdk/a11y';
import { NgModule }   from '@angular/core';

import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';

import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule }  from '@angular/cdk/drag-drop';
import { OverlayModule }   from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';

import { MatAutocompleteModule }       from '@angular/material/autocomplete';
import { MatDialogModule }             from '@angular/material/dialog';
import { MatExpansionModule }          from '@angular/material/expansion';
import { MatInputModule }              from '@angular/material/input';
import { MatMenuModule }               from '@angular/material/menu';
import { MatProgressBarModule }        from '@angular/material/progress-bar';
import { MatProgressSpinnerModule }    from '@angular/material/progress-spinner';
import { MatRadioModule }              from '@angular/material/radio';
import { MatSelectModule }             from '@angular/material/select';
import { MatSliderModule }             from '@angular/material/slider';
import { MatSlideToggleModule }        from '@angular/material/slide-toggle';
import { MatTooltipModule }            from '@angular/material/tooltip';
import { MatCheckboxModule }           from '@angular/material/checkbox';
import { MatTableModule }              from '@angular/material/table';
import { MatSortModule }               from '@angular/material/sort';
import { MatBadgeModule }              from '@angular/material/badge';
import { MatTreeModule }               from '@angular/material/tree';
import { MatIconModule }               from '@angular/material/icon';
import { MatButtonModule }             from '@angular/material/button';
import { MatDatepickerModule }         from '@angular/material/datepicker';
import { MatDividerModule }            from '@angular/material/divider';
import { MatListModule }               from '@angular/material/list';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTabsModule }               from '@angular/material/tabs';
import { MatChipsModule }              from '@angular/material/chips';
import { MatButtonToggleModule }       from '@angular/material/button-toggle';


// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
// noinspection SpellCheckingInspection
export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput:          'DD/MM/YYYY',
    monthYearLabel:     'MMM/YYYY',
    dateA11yLabel:      'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [],
  exports:      [
    A11yModule,
    ClipboardModule,
    DragDropModule,
    TextFieldModule,
    OverlayModule,
    MomentDateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    MatTreeModule,
    NgxMaterialTimepickerModule
  ],
  providers:    [
    {
      provide:  MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS
    }
  ]
})
export class MaterialModule {}
