import { NgModule }                  from '@angular/core';
import { CommonModule }              from '@angular/common';
import { FromCentsPipe }             from './from-cents.pipe';
import { DateFormatPipe }            from './date-format.pipe';
import { SafePipe }                  from './safe.pipe';
import { FromPercentagePipe }        from './from-percentage.pipe';
import { FromUtcPipe }               from './from-utc.pipe';
import { FromUnixPipe }              from './from-unix.pipe';
import { MapFromOptionsPipe }        from './map-from-options.pipe';
import { WhatsappUrlPipe }           from './whatsapp-url.pipe';
import { PhonePipe }                 from './phone.pipe';
import { FindByIdPipe }              from './filter-by-id.pipe';
import { SlugifyPipe }               from './slugify.pipe';
import { MomentDiffPipe }            from './moment-diff.pipe';
import { MomentInRangePipe }         from './moment-in-range.pipe';
import { SortByPipe }                from './sort-by.pipe';
import { IsNumberPipe }              from './is-number.pipe';
import { CompareObjectsPipe }        from './compare-objects.pipe';
import { IsSelectedAxisConceptPipe } from '@modules/essay/is-selected-axis-concept.pipe';


@NgModule({
  declarations: [
    FromCentsPipe,
    DateFormatPipe,
    SafePipe,
    FromPercentagePipe,
    FromUtcPipe,
    FromUnixPipe,
    MapFromOptionsPipe,
    WhatsappUrlPipe,
    PhonePipe,
    FindByIdPipe,
    SlugifyPipe,
    MomentDiffPipe,
    MomentInRangePipe,
    SortByPipe,
    IsNumberPipe,
    CompareObjectsPipe,
    IsSelectedAxisConceptPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FromCentsPipe,
    DateFormatPipe,
    SafePipe,
    FromPercentagePipe,
    FromUtcPipe,
    FromUnixPipe,
    MapFromOptionsPipe,
    WhatsappUrlPipe,
    PhonePipe,
    FindByIdPipe,
    SlugifyPipe,
    MomentDiffPipe,
    MomentInRangePipe,
    SortByPipe,
    IsNumberPipe,
    CompareObjectsPipe,
    IsSelectedAxisConceptPipe
  ]
})
export class PipesModule { }
