import { Injectable }                                                              from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { BehaviorSubject, Observable, of, throwError }   from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { httpInterceptorPaths } from './http-interceptor-paths';
import { UserService }          from '@services/user.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private refreshTokenSubject$: BehaviorSubject<any>;

  private readonly token$: Observable<string>;

  private readonly AUTH_HEADER: string;
  private readonly token:       string;

  private refreshTokenInProgress: boolean;

  constructor(
    private userService: UserService,
  ) {
    this.refreshTokenSubject$   = new BehaviorSubject<any>(null);
    this.AUTH_HEADER            = 'Authorization';
    this.refreshTokenInProgress = false;
    this.token                  = this.userService.getToken();

    this.token$ = this.userService.getTokenObservable();
  }

  private static refreshAccessToken(): Observable<any> {
    return of('secret token');
  }

  intercept(
    request: HttpRequest<any>,
    next:    HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes(httpInterceptorPaths.auth)) return next.handle(request);

    const handleNext = (clonedRequest: HttpRequest<any>) => next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready, and we can retry the clonedRequest again
            return this.refreshTokenSubject$.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(clonedRequest, this.token)))
            );

          } else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject$.next(null);

            return AuthInterceptor.refreshAccessToken().pipe(
              switchMap((success: boolean) => {
                this.refreshTokenSubject$.next(success);
                return next.handle(this.addAuthenticationToken(clonedRequest, this.token));
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => ( this.refreshTokenInProgress = false ))
            );
          }
        } else {
          return throwError(() => error);
        }
      })
    );

    return this.token$.pipe(
      take(1),
      switchMap(token => of(this.addAuthenticationToken(request, token))),
      switchMap(handleNext)
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>, token: string): HttpRequest<any> {

    // If we do not have a token yet, we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!token) return request;

    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(/redacaonota1000.com.br\//)) {
      return request;
    }

    const tokenStr: string = 'RED1000 ' + token;

    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, tokenStr)
    });
  }
}
