import { Inject, Injectable, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Script } from '@models/utils/script';


@Injectable({ providedIn: 'root' })
export class ScriptService implements OnDestroy {

  private readonly scriptsSource$: BehaviorSubject<Script[]>;
  private readonly scripts: Script[];

  private scripts$: Observable<Script[]>;
  private document: Document;

  public scriptsContent: { [scriptName: string]: string };

  constructor(
    @Inject(DOCUMENT) private _document: any
  ) {
    this.document = this._document as Document;

    this.scriptsSource$ = new BehaviorSubject([]);
    this.scripts$ = this.scriptsSource$.asObservable();

    this.scripts = [];
    this.scriptsContent = {};
  }

  public load(script: Script): Observable<Script> {
    if (!this.findScript(script.name)) {
      this.scripts.push({ ...script, loaded: false });
    }

    this.loadScript(script.name);

    return this.scripts$.pipe(
      map(scripts => this.findScript(script.name, scripts)),
      distinctUntilChanged()
    );
  }

  private findScript(
    scriptName: string,
    scripts: Script[] = this.scripts
  ): Script {
    return scripts.find(script => script.name === scriptName);
  }

  public scriptReady(scriptName: string): Observable<boolean> {

    if (!this.findScript(scriptName)) {
      return throwError(`the '${scriptName}' script was never initialized.`);
    }

    return this.scripts$.pipe(
      map(scripts => this.findScript(scriptName, scripts)),
      filter(script => !!script && (script.loaded === true)),
      map(script => script.loaded)
    );
  }

  public registerScriptContent(
    scriptName: string,
    scriptContent: string
  ): void {
    this.scriptsContent[scriptName] = scriptContent;
  }

  private loadScript(name: string): void {

    const index = this.scripts.findIndex((item) => item.name === name);

    // resolve if already loaded
    if (!this.scripts[index] || this.scripts[index].loaded) {
      this.scriptsSource$.next(this.scripts);
      return;
    }

    const scriptTag: HTMLElement = this.createScriptTag(this.scripts[index]);

    const callback: VoidFunction = () => {
      this.scripts[index].error = null;
      this.scripts[index].loaded = true;
      this.scriptsSource$.next(this.scripts);
    };

    const readyStateCallback: VoidFunction = () => {
      if (
        (this.scripts[index] as any).readyState === 'loaded' ||
        (this.scripts[index] as any).readyState === 'complete'
      ) {
        (this.scripts[index] as any).onreadystatechange = null;
        callback();
      }
    };

    // if the script has no src, there's no load event to track
    if (!this.scripts[index].src) {
      this.scripts[index].tag = scriptTag;
      this.document.head.appendChild(scriptTag);
      callback();
      return;
    }

    // cross browser handling of onLoad event
    if ((scriptTag as any).readyState) {
      (scriptTag as any).onreadystatechange = readyStateCallback; // IE
    } else {
      scriptTag.onload = callback; // Others
    }

    scriptTag.onerror = (error) => {
      this.scripts[index].loaded = false;
      this.scripts[index].error = error;
    };

    this.scripts[index].tag = scriptTag;
    this.document.head.appendChild(scriptTag);
  }


  public createScriptTag(script: Script): HTMLElement {
    const scriptTag: HTMLElement = this.document.createElement('script');

    scriptTag.setAttribute('type', script.type || 'text/javascript');
    scriptTag.setAttribute('name', script.name);

    if (script.src) { scriptTag.setAttribute('src', script.src); }
    if (script.async) { scriptTag.setAttribute('async', ''); }
    if (script.defer) { scriptTag.setAttribute('defer', ''); }

    const content: string = this.scriptsContent[script.name];

    if (content) { scriptTag.innerHTML = content; }

    return scriptTag;
  }

  public removeScriptTag(name: string): Observable<Script[]> {

    const index = this.scripts.findIndex((script) => script.name === name);

    if (index < 0) { return this.scriptsSource$; }

    this.document.head.removeChild(this.scripts[index].tag);
    this.scripts[index].loaded = false;
    this.scriptsSource$.next(this.scripts);

    return this.scriptsSource$;
  }

  ngOnDestroy(): void {
    for (const tag of this.scripts) {
      this.removeScriptTag(tag.name);
    }

    this.scriptsSource$.complete();
  }
}
