export const httpInterceptorPaths = {
  auth:     'api/v2',
  rd:       'api.rd.services',
  cache:    'cache',
  fake:     'fake',
  error:    'error',
  profiler: 'profiler',
  notify:   'notify',
  header:   'header',
  convert:  'convert',
  loader:   'loader',
  https:    'https'
};
