import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '../pipes/pipes.module';

import { PlaceholderImageDirective } from './placeholder-image.directive';
import { MarathonLogoComponent }     from './marathon-logo/marathon-logo.component';
import { BannerDebatemaComponent }   from './banner-debatema/banner-debatema.component';

import { IconsModule } from '@shared/icons/icons.module';


@NgModule({
  declarations: [
    PlaceholderImageDirective,
    MarathonLogoComponent,
    BannerDebatemaComponent
  ],
  imports:      [
    CommonModule,
    PipesModule,
    IconsModule
  ],
  exports:      [
    PlaceholderImageDirective,
    MarathonLogoComponent,
    BannerDebatemaComponent
  ],
})
export class ImageModule {}
