import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, tap }   from 'rxjs/operators';

import { SiteSection } from '@models/layout/site-section';
import { Menu }        from '@models/navigation/menu';
import { RED_LINKS }   from '@shared/menus/links';


@Injectable({ providedIn: 'root' })
export class LayoutService {

  private siteSectionSource$: BehaviorSubject<SiteSection>;
  private toolbarMenuSource$: BehaviorSubject<Menu>;

  public siteSection$: Observable<SiteSection>;
  public toolbarMenu$: Observable<Menu>;

  constructor() {
    this.siteSectionSource$ = new BehaviorSubject<SiteSection>(null);
    this.toolbarMenuSource$ = new BehaviorSubject<Menu>(null);
    this.toolbarMenu$       = this.toolbarMenuSource$.asObservable();

    this.siteSection$ = this.siteSectionSource$.asObservable().pipe(
      distinctUntilChanged(),
      tap(siteSection => this.setToolbarMenu(siteSection))
    );
  }

  private static selectToolbarMenuBySection(siteSection: SiteSection): Menu {

    switch (siteSection) {
      case SiteSection.INSTITUTIONAL_B2C:
        return {
          slug: 'institutional-students',
          anchor: [
            RED_LINKS['about'],
            RED_LINKS['students-how'],
            RED_LINKS['students-pricing'],
            RED_LINKS['schools-home'],
          ]
        };

      case SiteSection.INSTITUTIONAL_B2B:
        return {
          slug: 'institutional-schools',
          anchor: [
            RED_LINKS['about'],
            RED_LINKS['schools-resources'],
            RED_LINKS['schools-pilot'],
            RED_LINKS['students-home'],
          ]
        };

      case SiteSection.ABOUT:
        return {
          slug: 'about',
          anchor: [
            RED_LINKS['students-home'],
            RED_LINKS['schools-home'],
          ]
        };

      case SiteSection.CONTACT:
        return {
          slug: 'contact',
          anchor: [
            RED_LINKS['about'],
            RED_LINKS['students-home'],
            RED_LINKS['schools-home'],
          ]
        };

      default:
        return null;
    }

  }

  public getSiteSection(): Observable<SiteSection> {
    return this.siteSection$;
  }

  public setSiteSection(siteSection: SiteSection): void {
    this.siteSectionSource$.next(siteSection);
  }

  public getToolbarMenu(): Observable<Menu> {
    return this.toolbarMenu$;
  }

  public setToolbarMenu(toolbarType: SiteSection): void {
    this.toolbarMenuSource$.next(LayoutService.selectToolbarMenuBySection(toolbarType));
  }

  public completeObservables() {
    this.siteSectionSource$.complete();
    this.toolbarMenuSource$.complete();
  }
}
