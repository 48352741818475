import { HttpParams } from '@angular/common/http';
import { Router }     from '@angular/router';

import * as moment from 'moment';

import { RestPaginationAndSortingParams } from '@models/rest/rest-pagination-and-sorting-params';
import { GenericOption }                  from '@models/utils/generic-option';


const restPaginationDefaults: RestPaginationAndSortingParams = {
  page:          null,
  size:          24,
  sortProperty:  'datInclusao',
  sortDirection: 'desc'
};

export class TransformClass {

  public static getUrlWithoutParams(router: Router) {
    const urlTree = router.parseUrl(router.url);
    urlTree.queryParams = {};

    return urlTree.toString();
  }

  public static slugify(value: string): string {

    if (!value) return;

    let accent: RegExp[], noAccent: string[];

    // noinspection RegExpSimplifiable
    accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g,           // N, n
      /[\307]/g, /[\347]/g            // C, c
    ];

    noAccent = [ 'A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c' ];

    for (let i = accent.length - 1; i >= 0; i--) {
      value = value.replace(accent[i], noAccent[i]);
    }

    value = value.split(' ').join('-');                   // replace spaces with dashes
    value = value.split('/').join('-');                   // replace slashes with dashes
    value = value.split(',').join('');                    // remove commas
    value = value.replace(/[^\w\-]/gi, ''); // remove special characters
    value = value.toLocaleLowerCase();                             // make lower case

    return value;
  }

  public static ptBrList(list: (string | number)[]): string {
    if (list.length === 0) return '';
    if (list.length === 1) return `${ list[0] }`;

    const last: string | number         = list[list.length - 1],
          others: ( string | number )[] = list.slice(0, -1);

    return `${ others.join(', ') } e ${ last }`;
  }

  public static httpParams(params: object): HttpParams {
    const strParams = {};

    const isValidParam = (param) => param || Number.isInteger(param) || param === false;

    for (const key in params) {
      if (params.hasOwnProperty(key) && isValidParam(params[key])) {
        strParams[key.toString()] = params[key].toString();
      }
    }

    return new HttpParams({ fromObject: strParams });
  }

  public static formatPaginationParams(params: RestPaginationAndSortingParams): {
    page: number,
    size: number,
    sort: string
  } {
    const { page, size, ...sortParams }: Partial<RestPaginationAndSortingParams> = {
      ...restPaginationDefaults,
      ...params
    };

    const sort: string = `${ sortParams.sortProperty },${ sortParams.sortDirection }`;

    return { page, size, sort };
  }

  public static moment2String(
    date:   moment.Moment,
    format: string = 'DD/MM/YYYY'
  ): string {
    return !date ? null : date.format(format);
  }

  public static string2Moment(
    date:   string,
    format: string = 'DD/MM/YYYY HH:mm'
  ): moment.Moment {
    if (!date) return null;
    return moment(date, format);
  }

  public static getYearsArray(from: number) {
    const currentYear = moment().year();

    return Array.from(
      { length: currentYear - from + 1 },
      (_value, index) => from + index
    ).reverse();
  }

  public static getMonthOptions(): GenericOption<number>[] {
    return moment.months().map((month: string, index: number) => ({
      label: month, value: index + 1
    }));
  }

  public static compareObjects = (a, b) => JSON.stringify(a) === JSON.stringify(b);
}
