import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AnchorDirective } from './anchor.directive';


@NgModule({
  declarations: [
    AnchorDirective
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
  AnchorDirective
  ]
})
export class AnchorModule { }
